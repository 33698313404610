import React from "react";
import MainLayout from "../components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";
import CardStepProject from "../components/cardstepproject";
import stepCadreImage from "../images/step_project_cadre-min.jpg";
import stepDesignImage from "../images/step_project_design_user-min.jpg";
import stepDevelopImage from "../images/step_project_develop-min.jpg";
import stepMaintainsImage from "../images/step_project_maintain_cycle-min.jpg";
import LayoutCentered from "../components/layoutcentered";
import { Link } from "gatsby";

export default function Home() {
   const seoProps = {
      title: "Numeko - Nos expertises",
      description:
         "Conseil & gestion de projet, Graphisme (Design), Applicatif: Web, Mobile, Métier, API, devOps. Des spécialistes qualifiés pour vous accompagner dans la conception de projets web & mobiles performants et durables."
   };

   return (
      <MainLayout seoProps={seoProps}>
         <div className={"bloc-main expertises"}>
            <div className={"bloc-category bloc-presentation"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <h1>EXPERTISES</h1>
                  <h3>
                     Des <span>experts</span> pour répondre à tous <span>vos besoins</span> et à
                     tous <span>les enjeux</span>
                  </h3>
               </LayoutCentered>
            </div>

            <div className={"bloc-category bloc-content background-waves-one-color-duck"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"title"}>
                     <h1 className={"bold"}>Conseil & Stratégie</h1>
                     <h2>Cadrer vos projets</h2>
                  </div>

                  <p className={"description"}>
                     En fonction de l’étape d’avancement de votre projet,
                     nous vous <span className={"bold"}>accompagnons</span> pour transformer vos idées en <span
                     className={"bold"}>réussites business</span>
                     en un minimum de temps, tout en favorisant un numérique plus <span
                     className={"bold"}>soutenable</span> et <span className={"bold"}>durable.</span>
                  </p>

                  <div className={"container-card-expertise"}>
                     <CardStepProject title={"Audit / Analyse"}>
                        <p>Adopter la meilleure stratégie qui fera la différence. <br />
                           Donner les clés pour identifier et convertir vos idées et opportunités en outil
                           efficace</p>
                     </CardStepProject>
                     <CardStepProject title={"Vision produit"}>
                        <p>Etre en adéquation avec vos utilisateur, définir vos cibles, comprendre leurs
                           usages et
                           identifiez les fonctionnalités clés.</p>
                     </CardStepProject>
                     <CardStepProject title={"Assistance à maitrise d’ouvrage (AMOA)"}>
                        <p>Concevoir techniquement sur mobile et web votre application grâce aux
                           technologies adaptées et
                           des méthodes innovantes et efficientes.</p>
                     </CardStepProject>
                     <CardStepProject title={"Accompagnement décisionnel"}>
                        <p>Assurer la pérennité technique et le maintien en condition de votre application
                           pour un
                           fonctionnement optimal</p>
                     </CardStepProject>

                  </div>
               </LayoutCentered>

            </div>
            <div className={"bloc-category bloc-content-duck background-waves-two-color-white"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"title-right"}>
                     <h1 className={"bold"}>Design & Innovation</h1>
                     <h2>Concevoir auprès de vos utilisateurs</h2>
                  </div>

                  <p className={"description"}>
                     Répondre le plus <span className={"bold"}>efficacement</span> aux usages de <span
                     className={"bold"}>vos utilisateurs</span> pour un produit plus performant qui
                     répond à vos <span className={"bold"}>objectifs businnes.</span> Construire des outils <span
                     className={"bold"}>porteurs de sens, utiles, utisés et utilisables.</span>
                     <br />
                     Définissez vos cibles, comprenez leurs usages et identifiez les fonctionnalités
                     clés tout en intégrant les enjeux environnementaux et sociaux via l’éco-conception et
                     l’accessibilité numérique.
                  </p>

                  <div className={"container-card-expertise"}>
                     <CardStepProject classStyle={"container-card-duck"} title={"Définition de personas"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject classStyle={"container-card-duck"} title={"Parcours Utilisateurs"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject classStyle={"container-card-duck"} title={"Wirefrmaes (UX)"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject classStyle={"container-card-duck"} title={"Protype"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject classStyle={"container-card-duck"} title={"Tests utilisateurs"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject classStyle={"container-card-duck"} title={"Maquettage (UI)"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                  </div>
               </LayoutCentered>

            </div>

            <div className={"bloc-category bloc-content-tan background-waves-four-color-duck"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"title"}>
                     <h1 className={"bold"}>Développement technique</h1>
                     <h2>Développer vos applications</h2>
                  </div>

                  <p className={"description"}>
                     L’environnement technique de votre projet repose sur différents enjeux.
                     Nos experts développent vos applications en vous accompagnant dans les choix techniques les plus
                     adaptés à votre contexte et vos contraintes.<br />
                     Langage utilisé, interopérabilité, architecture... les points d’attention sont nombreux pour
                     développer une application qui réponde réellement à vos objectifs et aux objectifs d’inclusivité et
                     de sobriété.

                  </p>

                  <div className={"container-card-expertise"}>
                     <CardStepProject title={"Définition de personas"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject title={"Parcours Utilisateurs"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject title={"Wirefrmaes (UX)"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject title={"Protype"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject title={"Tests utilisateurs"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                     <CardStepProject title={"Maquettage (UI)"}>
                        <p>Comprendre votre métier et l’état de votre <span
                           className={"bold"}>écosystème existant</span> est primordiale pour commencer.
                           Nous réalisons aussi des audits d’<span className={"bold"}>écoconception</span> et d’<span
                              className={"bold"}>accessibilité</span></p>
                     </CardStepProject>
                  </div>
               </LayoutCentered>

            </div>
            <div className={"bloc-category bloc-content-padding background-waves-three-color-tan"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"title-right"}>
                     <h1 className={"bold"}>Maintenance technique</h1>
                     <h2>Maintenir le cycle de vie de vos application</h2>
                  </div>

                  <p className={"description"}>
                     Maintenir l’intégrité technique de votre application sur la durée est une nécessité pour assurer à
                     vos utilisateurs un usage constant et de qualité. Vous préservez ainsi leur satisfaction tout en
                     garantissant la sécurité et les performances de votre application.
                  </p>

                  <div className={"container-card-expertise"}>
                     <CardStepProject title={"Audit / Analyse"}>
                        <p>Adopter la meilleure stratégie qui fera la différence. <br />
                           Donner les clés pour identifier et convertir vos idées et opportunités en outil
                           efficace</p>
                     </CardStepProject>
                     <CardStepProject title={"Vision produit"}>
                        <p>Etre en adéquation avec vos utilisateur, définir vos cibles, comprendre leurs
                           usages et
                           identifiez les fonctionnalités clés.</p>
                     </CardStepProject>
                     <CardStepProject title={"Assistance à maitrise d’ouvrage (AMOA)"}>
                        <p>Concevoir techniquement sur mobile et web votre application grâce aux
                           technologies adaptées et
                           des méthodes innovantes et efficientes.</p>
                     </CardStepProject>
                     <CardStepProject title={"Accompagnement décisionnel"}>
                        <p>Assurer la pérennité technique et le maintien en condition de votre application
                           pour un
                           fonctionnement optimal</p>
                     </CardStepProject>

                  </div>
               </LayoutCentered>

            </div>

            <div className={"bloc-category bloc-contact background-waves-four-color-white"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <h1 className={"title"}>
                     Contactez-nous&nbsp;!
                  </h1>
                  <div className={"container-contact"}>
                     <div className={"element-contact"}>
                        <h3>Vous avez un projet ?</h3>
                        <p>
                           Vous êtes porteur de projet, en phase d’accélération
                           pour votre startup ou en recherche de productivité ?
                        </p>
                        <Link to={"/contact"}>Discutons en, ensemble</Link>
                     </div>
                     <div className={"element-contact"}>
                        <h3>Le job de vos rêves ?</h3>
                        <p>
                           Tu souhaites intégrer l’aventure Numeko et réaliser des projets ayant du sens.
                        </p>
                        <Link to={"/carrieres/"}>C'est ici qu'il se trouve</Link>
                     </div>
                     <div className={"element-contact"}>
                        <h3>Discuter ?</h3>
                        <p>
                           On aime partager et échanger en visio ou en physique.
                        </p>
                        <Link to={"/contact"}>Démarrer la discussion</Link>
                     </div>
                  </div>
               </LayoutCentered>
            </div>
         </div>
      </MainLayout>
   );
}
